import React from 'react';
import {Helmet} from 'react-helmet';

const Head = ({title = 'PNP Trauerportal', description = 'Traueranzeigen und Nachrufe für Altötting, Berchtesgadener Land, Deggendorf, Dingolfing-Landau, Freyung-Grafenau, Passau, Regen, Rottal-Inn, Traunstein', children}) => {
    return (
        <Helmet encodeSpecialCharacters={true}>
            <meta charSet="utf-8"/>
            <meta name="description" content={description}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            {children && children}
            <title>{title}</title>
        </Helmet>
    );
};

export default Head;
